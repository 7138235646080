import FormularioRegistro from './Elementos/FormularioRegistro';

const Registro = () => {
    return(
        <div className="container" style={{marginBottom: "13%"}}>
            <div className="mt-5" align="center">
                <FormularioRegistro />
            </div>
        </div>

      )
    }

export default Registro;