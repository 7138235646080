import FormularioInicioSesion from './Elementos/FormularioInicioSesion';

const InicioSesion = () => {
    return(
        <div className="container" style={{marginBottom: "35vh"}}>
            <div className="mt-5" align="center">
                <FormularioInicioSesion />
            </div>
        </div>
      )
    }

export default InicioSesion;